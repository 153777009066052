// import createAuth0Client from "@auth0/auth0-spa-js";
// import {
//   Auth0Client,
//   PopupTimeoutError,
//   PopupCancelledError
// } from "@auth0/auth0-spa-js";
import Vue from "vue";
const clone = require("rfdc")({
  proto: true
});
export const state = () => ({
  JARDEN_TOKEN_NAME: "jarden-auth-token",
  EQUIP_TIME_FORMAT: "YYYY-MM-DDTkk:mm.S",
  inviteCompany: {
    email: "contactus@jardengroup.co.nz",
    name: "Jarden Group",
    logo: "https://production-ranos-api-resources.s3-ap-southeast-2.amazonaws.com/abd5c16c-73a6-4fc8-8252-920779c563fd/logo_abd5c16c-73a6-4fc8-8252-920779c563fd_20200923025348.png"
  },
  preIPOCompanies: [
    {
      name: "Symal Group",
      tileName: "Jarden investor education research",
      expireTime: "30 October 2024",
      logo: "/jarden/logo/Symal-full-logo-white.png",
      shortText: function () {
        return `Jarden has invited you to view its ${this.name} investor education research, which is available on a read-only basis until ${this.expireTime}. Please click here to accept the terms and view this research.`;
      },
      termFile: "/jarden/terms/Jarden Ranos Terms of Use - October 2024.pdf",
      pdfP: "CVH7P4s9KcwgqV9A"
    }
  ],
  /*
  INCLUDE_SEC: [
  ],
  */
  getEquipError: false,
  EQUIP_REDIRECT_URI: process.env.BASE_URL + "/sscallback/",
  EQUIP_GRANT_TYPE: "authorization_code",
  EQUIP_CODE: "token"
});
/*
        name: "Grays.com Limited",
        tileName: "Investor education research",
        expireTime: "27 April 2021",
        logo: "/jarden/logo/GraysRedLogo.png",
        shortText: function () {
          return `Jarden has invited you to view its ${this.name} investor education research, which is available on a read-only basis until ${this.expireTime}. Please click here to accept the terms and view this research.`;
        },
        termFile: "/jarden/terms/22032021-g.pdf",
        pdfP: "CVH7P4s9KcwgqV9A"
*/
export const getters = {
  getAllCompanyNames: (state) =>
    state.preIPOCompanies.map((tmpCompany) => tmpCompany.name).join(", "),
  jardenEquipBaseUrl: (state, getters, rootState) =>
    rootState.app_store.isDev
      ? "https://equip-research.trading-equities.dev.jarden.work"
      : "https://equip-research.trading-equities.jarden.work",
  jardenEquipApiTitle:
    (state, getters, rootState, rootGetters) =>
    (id, time, originalTimeFormat, equipVersion) => {
      let returnMsg;
      // const equipVersionText = equipVersion ? `(id: ${equipVersion}) ` : "";
      switch (id) {
        case "wip":
          returnMsg = `Draft model from EQUIP`;
          break;
        default:
          returnMsg = `Live model from EQUIP`;
          break;
      }
      if (equipVersion) returnMsg += ` #${equipVersion}`;
      if (time) {
        if (!originalTimeFormat) originalTimeFormat = state.EQUIP_TIME_FORMAT;
        returnMsg += " | ";
        returnMsg += rootGetters["app_store/commonTimeString"](
          time,
          originalTimeFormat,
          rootState.change.MODEL_API_RETURN_FORMAT
        );
      }
      return returnMsg;
    },
  EQUIP_CLIENT_ID: (state, getters, rootState) =>
    rootState.app_store.isDev
      ? "4a495661-32ae-4806-a852-a6d588dd3051"
      : "e20d70dc-d026-4776-ad49-b81dce170a05",
  EQUIP_SCOPE: (state, getters, rootState) =>
    rootState.app_store.isDev
      ? "api://equip-research-dev/equip-research-user"
      : "api://equip-research-prd/.default"
};
export const mutations = {
  toggleEquipError(state, { trueOrFalse }) {
    Vue.set(state, "getEquipError", trueOrFalse);
  }
};
export const actions = {
  async remoteGetJardenEquipAuth({ state, getters, dispatch, rootState }) {
    let oauthDomain;
    if (rootState.app_store.isDev) {
      oauthDomain =
        "https://login.microsoftonline.com/77cbd237-5446-4937-b029-6276e110c885/oauth2/v2.0/authorize";
    } else {
      oauthDomain =
        "https://login.microsoftonline.com/77cbd237-5446-4937-b029-6276e110c885/oauth2/v2.0/authorize";
    }
    const formBodyObj = {
      grant_type: state.EQUIP_GRANT_TYPE,
      client_id: getters.EQUIP_CLIENT_ID,
      scope: getters.EQUIP_SCOPE,
      response_type: state.EQUIP_CODE,
      redirect_uri: state.EQUIP_REDIRECT_URI
    };
    let formBody = [];
    for (const property in formBodyObj) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(formBodyObj[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    formBody = formBody.join("&");
    const authUrl = oauthDomain + "?" + new URLSearchParams(formBodyObj);
    // what happens after user login
    //let loginPopup;
    const loginPopup = await dispatch(
      "app_store/remoteLoadPageInCenterPopup",
      {
        popupW: 800,
        popupH: 572,
        popupUrl: authUrl
      },
      { root: true }
    );
    if (!loginPopup) {
      throw new Error("Could not open pop up");
    }
    return new Promise((res) => {
      /* listener not reliable
      loginPopup.addEventListener('beforeunload', () => {
        console.lo('here');
        res();
      }, { once: true });
      */
      const tmpTimer = setInterval(() => {
        if (loginPopup.closed) {
          clearInterval(tmpTimer);
          res("true");
        }
      }, 1000);
    });
  },
  remoteSetJardenEquipTokenFromQueryParams({ state }, fullRoute) {
    const urlQuery = new URLSearchParams(fullRoute);
    if (urlQuery.size < 1) {
      console.error("no query parameters");
      throw new Error("Login failed. Please close this page and try again.");
    }
    let access_token, expires_in;
    if (urlQuery.has("#access_token"))
      access_token = urlQuery.get("#access_token");
    else {
      console.error("no access token");
      throw new Error("Login failed. Please close this page and try again.");
    }

    if (urlQuery.has("expires_in")) expires_in = urlQuery.get("expires_in");
    this.$cookies.set(state.JARDEN_TOKEN_NAME, access_token, {
      path: "/",
      maxAge: expires_in || 4621,
      secure: true
    });
    return true;
  },
  async remoteGetJardenEquipToken({ state, getters, rootState }, authCode) {
    // NOTE: this is not used anymore in favor of expicit auth code flow
    if (!authCode) {
      console.error("no auth code");
      throw new Error("no auth code");
    }
    let tokenDomain;
    if (rootState.app_store.isDev) {
      tokenDomain =
        "https://login.microsoftonline.com/77cbd237-5446-4937-b029-6276e110c885/oauth2/v2.0/token";
    } else {
      tokenDomain =
        "https://login.microsoftonline.com/77cbd237-5446-4937-b029-6276e110c885/oauth2/v2.0/token";
    }
    const formBodyObj = {
      client_id: getters.EQUIP_CLIENT_ID,
      client_secret: "",
      scope: getters.EQUIP_SCOPE,
      code: authCode,
      redirect_uri: state.EQUIP_REDIRECT_URI,
      grant_type: state.EQUIP_GRANT_TYPE
    };
    let formBody = [];
    for (const property in formBodyObj) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(formBodyObj[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    formBody = formBody.join("&");
    const reponse = await fetch(tokenDomain, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      body: formBody
    });

    const tokenData = await reponse.json();
    if (tokenData.access_token) {
      console.error("cannot get tokens");
      console.error(tokenData);
      return false;
    }
    this.$cookies.set(state.JARDEN_TOKEN_NAME, tokenData.access_token, {
      path: "/change/",
      maxAge: tokenData.expires_in || 60 * 60 * 2
    });
    return true;
  },
  async remoteGetJardenEquip({ dispatch, state }) {
    let jardenToken = this.$cookies.get(state.JARDEN_TOKEN_NAME);
    // check token exist
    if (jardenToken) {
      // what to do with token
      return jardenToken;
    }
    const actionRes = await dispatch("remoteGetJardenEquipAuth");
    jardenToken = this.$cookies.get(state.JARDEN_TOKEN_NAME);
    if (!jardenToken) {
      throw new Error("could not get token");
    }
    return jardenToken;
  },
  async remoteGetJardenEquipModels(
    { dispatch, rootState, rootGetters },
    { url, security_arr_index }
  ) {
    if (!url) return Promise.reject(new Error("invalid"));
    if (
      !rootState.change.EMPTY_NEW_CDB_METADATA ||
      !rootState.change.EMPTY_NEW_CDB_METADATA_INDEX_METRICS_ID_MAP
    )
      return Promise.reject(new Error("invalid new cdb"));
    // check / get token
    const remoteGetJardenToken = await dispatch("remoteGetJardenEquip");
    if (!remoteGetJardenToken)
      return Promise.reject(new Error("could not login"));
    try {
      if (
        !rootState.spreadsheet.cdbIdMap ||
        rootState.spreadsheet.cdbIdMap.length === 0
      ) {
        // for some reason api did get called on the page
        const getCDBMetrics = await dispatch(
          "spreadsheet/remoteGetCDBMetrics",
          null,
          {
            root: true
          }
        );
        if (!getCDBMetrics)
          throw Promise.reject(new Error("could not get cdb metrix"));
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${remoteGetJardenToken}`,
          "Content-Type": "application/json"
        }
      });
      if (!response) return Promise.reject(new Error("could not get model"));
      if (response.status === 401) {
        console.log(response);
      } else if (response.status === 400) {
        // no matching id
        const errorJson = await response.json();
        if (errorJson.detailMessage === "Ranos ID mapping not found")
          return Promise.reject(new Error("no matching id"));
        else if (errorJson.error === "Constraint violation")
          return Promise.reject(new Error("Incorrect api format"));
        else if (errorJson.error === "User is not setup as covering analyst")
          return Promise.reject(new Error("not authorized"));
      } else if (response.status === 500) {
        return Promise.reject(new Error("could not get model"));
      } else if (response.status === 200) {
        //commit("toggleGetEquipModel", true);
        dispatch("spreadsheet/remoteToggleLoading", true, {
          root: true
        }); // show saving
        const updatedLinkSec =
          security_arr_index !== null
            ? clone(rootState.change.linkedSecurities[security_arr_index])
            : null;
        // empty the new cdb
        if (updatedLinkSec) {
          updatedLinkSec.change_security_new_cdb.model_sheet_metadata = clone(
            rootState.change.EMPTY_NEW_CDB_METADATA
          );
        }
        const respJson = await response.json();
        // NOTE when the ID is wrong it just 200 with empty data
        //commit("toggleGetEquipModel", false);
        if (!respJson || respJson.length === 0)
          return Promise.reject(new Error("Ranos ID mapping not found"));
        if (!rootState.spreadsheet.worksheets) {
          // if dont have the sheet details
        }
        const updateCellArray = []; // update on spreadsheet tool
        const updateCellFormatArray = [];
        const additionalMetadata = {};
        for (let i = 0; i < respJson.length; i++) {
          const resultObj = respJson[i];
          // if not a valid result
          if (!resultObj || !resultObj.metricsId || !resultObj.metricsParams)
            continue;
          const checkMetricId = parseInt(resultObj.metricsId, 10);
          if (isNaN(checkMetricId)) continue;
          if (checkMetricId === 9999) {
            // submission date
          } else if (checkMetricId === 10000) {
            // submission id
          } else if (checkMetricId === 10001) {
            const tmpRecommendation = resultObj.metricsParams[1] || "";
            additionalMetadata["change_security_recommendation"] =
              tmpRecommendation;
            // rating
            if (updatedLinkSec) {
              // update new recommendation
              updatedLinkSec.change_security_recommendation = tmpRecommendation;
            }
          } else if (checkMetricId === 245) {
            // esg rating
            const tmpEsgRating = resultObj.metricsParams[1] || "";
            additionalMetadata["change_security_esg_text"] = tmpEsgRating;
            // rating
            if (updatedLinkSec) {
              // update new recommendation
              updatedLinkSec.change_security_esg_text = tmpEsgRating;
            }
          } else {
            // use the order first for speed, but if the id does not match use find
            const cdbMap =
              checkMetricId === rootState.spreadsheet.cdbIdMap[i]?.metric_id
                ? rootState.spreadsheet.cdbIdMap[i]
                : rootState.spreadsheet.cdbIdMap.find(
                    (cdbIdObj) => cdbIdObj.metric_id === checkMetricId
                  );
            if (!cdbMap || !cdbMap.params_cells) continue;
            let isPercentage = false; // default return as number
            for (let j = 0; j < resultObj.metricsParams.length; j++) {
              const cellNumber = cdbMap.params_cells[j];
              if (!cellNumber) continue;
              const cellDecode =
                rootGetters["spreadsheet/decodeCell"](cellNumber);
              if (!cellDecode) continue;
              const cellUnit = cdbMap.params[j];
              const inputResult = resultObj.metricsParams[j] || "-";
              if (cellUnit === "unit") {
                isPercentage = inputResult === "%" ? true : false; // if its percentage calculate
              }
              let updateR, displayR;
              updateR = displayR = inputResult;
              if (cellUnit !== "unit" && typeof inputResult === "string") {
                if (inputResult.trim().length === 0) continue; // if the result is empty
                try {
                  // try to convert string to dec
                  const tmpCheckString = inputResult.replace("/,/g", ""); // try getting rid of comma
                  if (!isNaN(tmpCheckString)) {
                    // make sure the string can be a number
                    updateR = parseFloat(tmpCheckString);
                    if (isPercentage) {
                      //updateR = toFloatVal;
                      displayR = (updateR * 100).toFixed(1) + "%";
                    } else if (checkMetricId === 150) {
                      // current financial year cant be in float
                      updateR = parseInt(tmpCheckString);
                      displayR = `${updateR}`;
                      // this is only done once as there should be 1 value
                      if (updatedLinkSec) {
                        const fyPointer = rootGetters[
                          "app_store/objectNestedPropertyCheck"
                        ](
                          updatedLinkSec,
                          ["change_security_new_cdb", "fy_options"],
                          null
                        );
                        // update financial years so not relying on lambda
                        if (fyPointer) {
                          const loopKeys = Object.keys(fyPointer);
                          const loopKeysLen = loopKeys.length;
                          for (let k = 0; k < loopKeysLen; k++) {
                            const tmpFyKey = loopKeys[k];
                            if (tmpFyKey === "t") {
                              // change to current year
                              fyPointer[tmpFyKey] = displayR;
                            } else {
                              // has operators
                              const operatingYear = parseInt(
                                tmpFyKey.charAt(2)
                              );
                              if (!isNaN(operatingYear)) {
                                if (tmpFyKey.charAt(1) === "-") {
                                  // negative year
                                  fyPointer[tmpFyKey] =
                                    updateR - operatingYear + "A";
                                } else {
                                  // positive year
                                  fyPointer[tmpFyKey] =
                                    updateR + operatingYear - 1 + "E";
                                } // end charAt
                              } //end isNaN
                            } // end else
                          } // end k
                        } // end fy pointer
                      } // end updatedLinkSec
                    } else if (
                      rootState.cdbSheet.ONE_DP_METRICS.includes(checkMetricId)
                    ) {
                      // known 1dp values
                      //updateR = toFloatVal;
                      displayR = updateR.toFixed(1) + "%";
                    } else {
                      let fixedDp = 1; // default number decimal is 1
                      if (
                        rootState.cdbSheet.TWO_DP_METRICS.includes(
                          checkMetricId
                        )
                      )
                        fixedDp = 2;
                      //updateR = toFloatVal;
                      displayR = rootGetters["app_store/numberDecimal"](
                        updateR,
                        fixedDp
                      ); // if not percentage display is the same
                    }
                  } // !isNaN
                } catch (e) {
                  console.error(e);
                  console.error(
                    `caonot convert ${inputResult} to float for cell ${cellNumber}`
                  );
                  updateR = inputResult;
                  displayR = inputResult;
                }
              }
              const pushingObj = {
                id: cellNumber,
                col: cellDecode.c,
                row: cellDecode.r,
                value: updateR,
                sheetName: rootState.spreadsheet.CDB_SHEET_NAME
              };
              if (checkMetricId === 150 && cellUnit !== "unit") {
                // setting current financial year to text format
                updateCellFormatArray.push({
                  endColIndex: cellDecode.c,
                  startColIndex: cellDecode.c,
                  startRowIndex: cellDecode.r,
                  endRowIndex: cellDecode.r,
                  format: "General"
                });
              }
              updateCellArray.push(pushingObj);
              if (checkMetricId == 127 && cellUnit !== "unit") {
                // target price
                additionalMetadata["change_security_targetprice"] =
                  updateR && updateR !== "-" ? updateR : 0;
              }
              if (updatedLinkSec) {
                if (checkMetricId == 127 && cellUnit !== "unit") {
                  // target price
                  updatedLinkSec.change_security_targetprice =
                    updateR && updateR !== "-" ? updateR : 0;
                }
                // need to update change linkedSecurities state, but only just a clone so can update all securities on the report side with one commit
                /*
                const tmpMetricsToChange =
                  updatedLinkSec.change_security_new_cdb.model_sheet_metadata.find(
                    (tmpSheetMetricObj) =>
                      tmpSheetMetricObj.metrics_id === checkMetricId
                  );
                */
                const tmpMetricsToChange = rootState.change
                  .EMPTY_NEW_CDB_METADATA_INDEX_METRICS_ID_MAP[checkMetricId]
                  ? updatedLinkSec.change_security_new_cdb.model_sheet_metadata[
                      rootState.change
                        .EMPTY_NEW_CDB_METADATA_INDEX_METRICS_ID_MAP[
                        checkMetricId
                      ]
                    ]
                  : null;
                if (!tmpMetricsToChange) continue;
                // const tmpMetricKey = cdbMap.params[j];
                tmpMetricsToChange.op_sheet_row_values[cellUnit] = displayR; // diplay the formated value in the tab list
              }
            } // j
          } // else
        } /// i
        dispatch("spreadsheet/remoteToggleLoading", false, { root: true }); // show not saving
        if (updatedLinkSec) {
          return Promise.resolve({
            updateCellArray,
            updateCellFormatArray,
            updatedLinkSec
          });
        } else {
          return Promise.resolve({
            updateCellArray,
            updateCellFormatArray,
            additionalMetadata
          });
        }
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async remoteGetJardenEquipModelTimes(
    { dispatch, getters, rootGetters },
    { security_id, type = false }
  ) {
    if (!security_id) return Promise.reject(new Error("no security id"));
    // check / get token
    const remoteGetJardenToken = await dispatch("remoteGetJardenEquip");
    if (!remoteGetJardenToken)
      return Promise.reject(new Error("could not login"));
    // NOTE published first, second one is draft
    let urlArray = [
      `${getters.jardenEquipBaseUrl}/company/companydatainfo/submissiondetails?companygroup=ranos-Id=${security_id}&formula=submission_date&datacontext=live`
    ];
    let returnArray = [
      {
        name: "",
        id: "live",
        apiUrl: `${getters.jardenEquipBaseUrl}/ranos/?ranosId=${security_id}&dataContext=live`
      }
    ];
    if (!type) {
      urlArray.push(
        `${getters.jardenEquipBaseUrl}/company/companydatainfo/submissiondetails?companygroup=ranos-Id=${security_id}&formula=submission_date&datacontext=wip`
      );
      returnArray.push({
        name: "",
        id: "wip",
        apiUrl: `${getters.jardenEquipBaseUrl}/ranos/?ranosId=${security_id}&dataContext=wip`
      });
    } else if (type == "wip") {
      urlArray = [
        `${getters.jardenEquipBaseUrl}/company/companydatainfo/submissiondetails?companygroup=ranos-Id=${security_id}&formula=submission_date&datacontext=wip`
      ];
      returnArray = [
        {
          name: "",
          id: "wip",
          apiUrl: `${getters.jardenEquipBaseUrl}/ranos/?ranosId=${security_id}&dataContext=wip`
        }
      ];
    }
    try {
      const responses = await Promise.allSettled(
        urlArray.map((url) =>
          fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${remoteGetJardenToken}`,
              "Content-Type": "application/json"
            }
          })
        )
      );
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i];
        if (response.status === "fulfilled") {
          const respJson = await response.value.json();
          if (response.value.status === 200) {
            const tmpSubmissionId = respJson.submission_id || 0;
            if (respJson.submission_date) {
              returnArray[i].data_source = "EQUIP";
              returnArray[i].name = getters.jardenEquipApiTitle(
                returnArray[i].id,
                respJson.submission_date,
                state.EQUIP_TIME_FORMAT,
                tmpSubmissionId
              );
              returnArray[i].lastUpdatedTime = rootGetters[
                "app_store/getUnixTimeFromString"
              ](respJson.submission_date, state.EQUIP_TIME_FORMAT, true);
              returnArray[i].extra_info = {
                equip_submission_id: tmpSubmissionId
              };
            }
          } else if (response.value.status === 401) {
            returnArray[i].name = getters.jardenEquipApiTitle(
              returnArray[i].id
            );
            returnArray[i].name += " ( unauthorised )";
            returnArray[i].error = "not authorised";
          } else if (response.value.status === 400) {
            returnArray[i].name = getters.jardenEquipApiTitle(
              returnArray[i].id
            );
            returnArray[i].name += " ( unavailable )";
            returnArray[i].error = "unavailable";
          }
        } else {
          /*
          // not authorized
          returnArray[i].name += " (unauthorised)";
        } else if (response.status === 400) {
          // no matching id
          const errorJson = await response.json();
          if (errorJson.detailMessage === "Ranos ID mapping not found")
            return Promise.reject(new Error("no matching id"));
          else if (errorJson.error === "Incorrect formula")
            returnArray[i].name += " (incorrect formula)";
            // return Promise.reject(new Error("Incorrect api format"));
        } else {
          // other errors
          console.error(response);
          return Promise.reject(new Error("do no get model"));
        }
        */
          returnArray[i].name = getters.jardenEquipApiTitle(returnArray[i].id);
          const errorJson = await response.reason.json();
          if (response.reason.status === 401)
            returnArray[i].name += " ( unauthorised )";
          else if (response.reason.status === 400) {
            if (errorJson.detailMessage === "Ranos ID mapping not found")
              return Promise.reject(new Error("no matching id"));
            else if (errorJson.error === "Incorrect formula")
              returnArray[i].name += " ( unavailable )";
          }
        }
      }
      return Promise.resolve(returnArray);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async remoteCheckLinkSecuritiesEquipModel(
    { state, rootState, rootGetters, dispatch },
    { securities, forceLive }
  ) {
    if (!securities) securities = rootState.change.linkedSecurities;
    let modelTimeApiPromises = [];
    // NOTE need to change
    if (rootState.app_store.isDev && 2 < 1) {
      modelTimeApiPromises = [
        dispatch("remoteGetJardenEquipModelTimes", {
          security_id: "bd7dc7ea-0199-4f1b-8397-ee1e06771c24",
          type: "live"
        })
      ];
    } else {
      modelTimeApiPromises = securities.map((tmpSec) => {
        if (!tmpSec.security_id) return Promise.reject("dont have security id");
        // NOTE to be remove
        /*
        if (!state.INCLUDE_SEC.includes(tmpSec.security_id)) {
          return Promise.resolve("do not get model");
        }
        */
        // ===
        if (forceLive) {
          // if forcing live model
          return dispatch("remoteGetJardenEquipModelTimes", {
            security_id: tmpSec.security_id,
            type: "live"
          });
        }
        const tmpModelMetadata = rootGetters[
          "app_store/objectNestedPropertyCheck"
        ](tmpSec, ["model", "model_metadata"], null);
        if (!tmpModelMetadata)
          // if no action in ssheet tool then just call live number
          return dispatch("remoteGetJardenEquipModelTimes", {
            security_id: tmpSec.security_id,
            type: "live"
          });
        if (
          tmpModelMetadata.data_source &&
          ["EXCEL", "RANOS"].includes(tmpModelMetadata.data_source)
        ) {
          // if the data is from ranos or excel dont get model
          return Promise.resolve("do not get model");
        }
        const savedRemoteModelData = tmpModelMetadata.external_api || null;
        let type = "live";
        if (savedRemoteModelData) {
          // previously has stored api info
          // check if they have updated the model manually after, if so dont call api
          if (
            savedRemoteModelData.model_updated_time &&
            !savedRemoteModelData.ranos_save_time
          )
            return Promise.resolve("do not get model");
          if (savedRemoteModelData.id) type = savedRemoteModelData.id;
        }
        return dispatch("remoteGetJardenEquipModelTimes", {
          security_id: tmpSec.security_id,
          type: type
        });
      });
    }
    const allRemoteModelTimes = await Promise.allSettled(modelTimeApiPromises);
    const modelApiPromises = [];
    const modelIdMap = [];
    for (let i = 0; i < securities.length; i++) {
      const modelTimeR = allRemoteModelTimes[i];
      if (!modelTimeR) continue;
      if (modelTimeR.status == "rejected") {
        //modelApiPromises.push(Promise.reject(false));
        // if user close the popup
        if (modelTimeR.reason && modelTimeR.reason.error) {
          let tmpErrorMsg;
          switch (modelTimeR.reason.error) {
            case "cancelled":
              tmpErrorMsg = "canceled login";
              break;
            case "timeout":
              tmpErrorMsg = "timed out";
          }
          return Promise.reject(tmpErrorMsg);
        }
        continue;
      }
      const shouldGetModel = modelTimeR.value;
      const tmpSec = securities[i];
      if (!tmpSec || !tmpSec.model || !tmpSec.model.model_id) {
        //modelApiPromises.push(Promise.reject(false));
        continue;
      }
      if (shouldGetModel !== "do not get model") {
        /*
        const selectedItem = shouldGetModel.find(
          (tmpSelect) => tmpSelect.id === shouldGetModel
        );
        */
        const selectedItem = shouldGetModel[0]; // only call 1 api now so should always be the first one
        if (!selectedItem || selectedItem.error) {
          //modelApiPromises.push(Promise.reject(false));
          continue;
        }
        const savedRemoteModelData = rootGetters[
          "app_store/objectNestedPropertyCheck"
        ](tmpSec, ["model", "model_metadata", "external_api"], false);
        if (
          savedRemoteModelData &&
          savedRemoteModelData.model_updated_time &&
          !forceLive
        ) {
          // check if the time is greater before choosing to get the data again
          if (
            selectedItem.lastUpdatedTime <=
            savedRemoteModelData.model_updated_time
          )
            continue;
        }
        const cdbSheetIndex = tmpSec.model.model_sheets.findIndex(
          (tmpSheet) => tmpSheet.model_sheet_cdb
        );
        if (cdbSheetIndex < 0) continue;
        modelIdMap.push({
          model_id: tmpSec.model.model_id,
          dataId: selectedItem.id,
          modelUpdTime: selectedItem.lastUpdatedTime,
          extraInfo: selectedItem.extra_info || 0,
          security_arr_index: i,
          cdb_sheet_index: cdbSheetIndex,
          cdb_sheet_id: tmpSec.model.model_sheets[cdbSheetIndex].model_sheet_id
        });
        modelApiPromises.push(
          dispatch("remoteGetJardenEquipModels", {
            url: selectedItem.apiUrl,
            security_arr_index: i
          })
        );
      }
    }
    if (modelApiPromises.length === 0)
      return Promise.resolve("dont need to get any models");
    const allRemoteModels = await Promise.allSettled(modelApiPromises);
    const returnArray = [];
    for (let i = 0; i < allRemoteModels.length; i++) {
      const tmpModelData = allRemoteModels[i];
      if (tmpModelData.status == "rejected") {
        console.error(tmpModelData);
        continue;
      }
      const modelDetails = modelIdMap[i];
      if (!modelDetails) {
        console.error("did not get save model detail in loop");
        continue;
      }
      const tmpModelId = modelDetails.model_id;
      if (!tmpModelId) {
        console.error("did not get model id from ranos loop");
        continue;
      }
      const setUpTmpWS = await dispatch(
        "spreadsheet/remoteGetSecurityModelData",
        tmpModelId,
        {
          root: true
        }
      );
      if (!setUpTmpWS) continue;
      await dispatch("spreadsheet/remoteEmptyWorksheetData", null, {
        root: true
      });
      await dispatch("spreadsheet/remoteGetSheetData", null, {
        root: true
      });
      try {
        if (
          tmpModelData.value.updateCellArray &&
          tmpModelData.value.updateCellArray.length > 0
        ) {
          const updatingR = await dispatch(
            "spreadsheet/saveCellValue",
            tmpModelData.value.updateCellArray,
            {
              root: true
            }
          );
          if (!updatingR) {
            console.error("could not update spreadsheet");
            continue;
          }
        }
        if (
          tmpModelData.value.updateCellFormatArray &&
          tmpModelData.value.updateCellFormatArray.length > 0
        ) {
          tmpModelData.value.updateCellFormatArray.forEach(async (tmpObj) => {
            await dispatch("spreadsheet/remoteUpdateCellsFormat", tmpObj, {
              root: true
            });
          });
        }
        // have to update metadata first as it calls the api in save model
        const new_model_metadata = {
          external_api: {
            id: modelDetails.dataId,
            model_updated_time: modelDetails.modelUpdTime,
            extra_info: modelDetails.extraInfo,
            ranos_save_time: Date.now()
          },
          data_source: "EQUIP"
        };
        await dispatch(
          "spreadsheet/remoteUpdateModelMetadata",
          {
            metadata: new_model_metadata
          },
          {
            root: true
          }
        );
        await dispatch("spreadsheet/remoteSaveWholeModel", tmpModelId, {
          root: true
        });
        // update model_metadata
        const tmpKeys = Object.keys(new_model_metadata);
        const tmpSecurityObj = tmpModelData.value.updatedLinkSec;
        if (!tmpSecurityObj.model) tmpSecurityObj.model = {};
        if (!tmpSecurityObj.model.model_metadata)
          tmpSecurityObj.model.model_metadata = {};
        for (let j = 0; j < tmpKeys.length; j++) {
          const tmpKey = tmpKeys[j];
          tmpSecurityObj.model.model_metadata[tmpKey] =
            new_model_metadata[tmpKey];
        }
        //===
        returnArray.push({
          security_arr_index: modelDetails.security_arr_index,
          new_linked_sec_details: tmpSecurityObj
        });
      } catch (e) {
        console.error(e);
        continue;
      }
    } // i
    return Promise.resolve(returnArray);
  }
};
