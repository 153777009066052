export const TRANSLATIONS = {
  en: {
    PUBLICATION_LIST_PAGE: {
      ERROR_MESSAGES: {
        AUTHORISATION:
          "Please contact an administrator driectly or through the Entity page to request the appropriate authorisations.",
        DOWNLOAD_PRINT_ERROR:
          "Downloads and prints require authorisation. Please contact an administrator to request the appropriate access."
      }
    },
    ACCOUNT_PAGE: {
      ERROR_MESSAGES: {
        MISSING_DATA: "Please populate all fields to create an account.",
        PASSWORD: {
          NOT_MATCH:
            "The passwords you have entered do not match. Please check or re-enter them.",
          NOT_VALID:
            "The password you have entered is invalid. Please use between five and fifteen characters without spaces.",
          INCORRECT:
            "The password you have entered is incorrect. Please re-enter or reset your password to login."
        },
        NAME: {
          NOT_VALID:
            "The name you have entered is invalid. Please use between two and twenty-five characters including spaces, numbers and special characters."
        },
        EMAIL: {
          NOT_VALID:
            "The email you have entered may be incorrect or invalid. Please re-enter it or try a different address.",
          IN_USE:
            "The email you have entered is already in use. Please use a different email or use it to login instead.",
          NOT_EXIST:
            "The email you have entered is unregistered or incorrect. Please create an account or re-enter your email to login.",
          NOT_EXIST_2:
            "The email you have entered is unregistered or incorrect. Please create an account or re-enter your email to reset your password."
        },
        PHONE: {
          NOT_VALID: "Please populate a valid mobile number",
          NOT_VALID_2:
            "The number you have entered may be incorrect or invalid. Please re-enter it or try a different number."
        },
        CODE: {
          INCORRECT:
            "The code you have entered is incorrect or expired. Please re-enter or resend your code to create your account.",
          RESEND:
            "Your code has been resent. Please check your email and junk mail for your unique account verification code. Please note this may take several minutes.",
          MISSING: "Please enter account verification code."
        },
        MEMBER_INVITE:
          "The link you clicked is invalid or has expired. This could mean you already have access to the entity, you have used an expired or invalid invitation, your invitation has already been used by another person or you have accesses this webpage directly without receiving an invitation. Please login to your account or request a new invitation from an administrator of the entity you want to access. You can also email us at admin@ranos.io for help.",
        REPORT_EXPIRE:
          "The link you clicked is invalid or has expired. This could mean the report has been changed or deleted. Please login to your account or request a new report from the member who originally sent it to you. You can also email us at admin@ranos.io for help."
      },
      MESSAGES: {
        EMAIL_LINK:
          "Please check your email for a link that will enable you to change your password."
      }
    },
    FILE_UPLOADING: {
      ERROR_MESSAGES: {
        FILE_TYPE:
          "Invalid file size, number or format. Please upload a single PDF under 20Mb.",
        REFERENCE_NOTIFICATION_FILE_TYPE:
          "Invalid file size, number or format. Please reupload a conventional file format and size",
        RGPT_FILE_TYPE:
          "Invalid file size, number or format. Please upload a single PDF under 20Mb with less than 100 pages.",
        ICON: "Invalid file size, number or format. Please upload a single PNG image under 200kb."
      }
    }
  }
};
