<template>
  <div
    ref="card"
    class="platform-card"
    :class="{
      'no-padding': noPadding,
      'no-lines': noLines,
      'actions-hidden': hiddenActions,
      'no-overflow': noOverflow,
      'card-type-blue': blue,
      'not-shown': internalNotShown,
      'no-scroll': noScroll,
      expanded: isExpanded,
      'no-border-radius': isFullscreen,
      'is-expanding': isExpanding,
      tablet: isTablet,
      collapsed: isCollapsed
    }"
    :style="{
      height: !!height ? height : 'auto',
      maxWidth: !!width ? width : '100%',
      width: !!width ? width : '100%',
      left: x,
      top: y,
      position: isPositioned ? 'absolute' : 'static'
    }"
  >
    <div v-show="hasHeader" ref="header" class="header">
      <slot name="header"></slot>
    </div>

    <div
      ref="content"
      class="card-content scroll-area"
      :style="{
        height: isCollapsed ? 0 : contentHeight || undefined
      }"
    >
      <slot></slot>
    </div>

    <div v-if="hasActions" ref="actions" class="actions">
      <slot name="actions"></slot>
    </div>

    <div v-if="hasFooter" ref="footer" class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  // [DEPRACATED]
  provide() {
    const reactiveData = {};

    Object.defineProperty(reactiveData, "loading", {
      get: () => this.loading
    });

    return { reactiveData };
  },
  props: {
    // loading state for providing for other elements
    // [DEPRACATED]
    loading: {
      type: Boolean,
      default: false
    },
    // remove padding in the card content area
    noPadding: {
      type: Boolean,
      default: false
    },
    // hiding the lines in the card (for dialogs and such)
    noLines: {
      type: Boolean,
      default: false
    },
    // hiding the cards that go under expanded cards
    notShown: {
      type: Boolean,
      default: false
    },
    // for menus that come out of the card
    hiddenActions: {
      type: Boolean,
      default: false
    },
    // for menus that come out of the card
    noOverflow: {
      type: Boolean,
      default: false
    },
    // expanded dialog card
    expanded: {
      type: Boolean,
      default: false
    },
    // expanded full inside layout content
    expandedPanel: {
      type: Boolean,
      default: false
    },
    // expanded full height only inside layout content
    expandedPanelY: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    // for gray background cards
    blue: {
      type: Boolean,
      default: false
    },
    // for no scroll cards that cut off content
    noScroll: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    savedHeight: 0,
    savedWidth: 0,
    savedX: 0,
    savedY: 0,
    contentHeight: 0,
    isPositioned: false,
    isExpanded: false,
    isExpanding: false,
    internalNotShown: false,
    isFullscreen: false,
    isCollapsed: false,
    savedCollapsed: false
  }),

  computed: {
    ...mapGetters({
      isTablet: "app_store/isTablet"
    }),
    hasHeader() {
      return !!this.$slots.header;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },

  watch: {
    expanded(val) {
      this.prepareExpandPanel(val, true);
    },

    expandedPanel(val) {
      if (this.isTablet) this.prepareExpandPanel(val, true);
      else this.prepareExpandPanel(val);
    },

    expandedPanelY(val) {
      if (this.isTablet) this.prepareExpandPanel(val, true);
      else this.prepareExpandPanel(val, false, false);
    },

    collapsed: {
      handler(val) {
        this.prepareCollapsePanel(val);
      }
    },

    notShown: {
      async handler(val) {
        if (val) {
          this.internalNotShown = true;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 500));
          this.internalNotShown = false;
        }
      },
      immediate: true
    }
  },

  destroyed() {
    this.unblockScroll();
  },

  mounted() {
    this.prepareCollapsePanel(this.collapsed);
  },

  methods: {
    blockScroll() {
      document.body.style.overflow = "hidden";
    },
    unblockScroll() {
      if (!parseInt(document.body.dataset.dialogOpen))
        document.body.style.overflow = "auto";
    },

    async prepareCollapsePanel(val) {
      if (val) {
        const contentHeight = this.$refs.content.offsetHeight + "px";
        this.contentHeight = contentHeight;
        this.isExanding = true;
        await new Promise((resolve) => setTimeout(resolve, 10));
        this.isCollapsed = true;
        await new Promise((resolve) => setTimeout(resolve, 300));
        this.isExpanding = false;
      } else if (this.isCollapsed) {
        this.isCollapsed = false;
        this.isExpanding = true;
        await new Promise((resolve) => setTimeout(resolve, 300));
        this.isExpanding = false;
        this.contentHeight = 0;
      }
      return;
    },
    async prepareExpandPanel(
      triggerValue,
      fullscreen = false,
      widthFlag = true,
      heightFlag = true,
      options = {}
    ) {
      if (triggerValue) {
        this.isFullscreen = fullscreen;
        if (fullscreen) this.blockScroll();
        this.isExpanding = true;

        const refHeight = this.$refs.card.offsetHeight + "px";
        this.height = refHeight;
        this.savedHeight = refHeight;

        const refWidth = this.$refs.card.offsetWidth + "px";
        this.width = refWidth;
        this.savedWidth = refWidth;

        const rect = this.$refs.card.getBoundingClientRect();
        const refY = rect.top + window.scrollY + "px";
        const refX = rect.left + window.scrollX + "px";

        this.savedX = refX;
        this.savedY = refY;

        this.x = refX;
        this.y = refY;

        // Waiting for other cards to hide
        await new Promise((resolve) => setTimeout(resolve, 300));

        if (this.collapsed) {
          this.prepareCollapsePanel(false);
        }

        this.isPositioned = true;

        this.x = fullscreen
          ? window.scrollX + "px"
          : options?.x ?? "var(--dashboard-gap)";
        this.y = fullscreen
          ? window.scrollY + "px"
          : options?.y ?? "calc(var(--dashboard-gap) + var(--menu-height))";

        if (heightFlag) {
          this.height = fullscreen
            ? "100vh"
            : options?.height ?? "var(--content-height)";
        }

        if (widthFlag) {
          this.width = fullscreen
            ? "100vw"
            : options?.width ?? "var(--content-width)";
        }

        this.isExpanded = true;
        this.isExpanding = false;
      } else {
        this.isExpanding = true;
        this.isFullscreen = false;
        this.unblockScroll();
        if (this.savedWidth || this.savedHeight || this.x || this.y) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        this.isExpanded = false;

        if (this.savedWidth) {
          this.width = this.savedWidth;
          this.savedWidth = 0;
        }

        if (this.savedHeight) {
          this.height = this.savedHeight;
          this.savedHeight = 0;
        }

        if (this.savedX) {
          this.x = this.savedX;
          this.savedX = 0;
        }
        if (this.savedY) {
          this.y = this.savedY;
          this.savedY = 0;
        }

        await new Promise((resolve) => setTimeout(resolve, 300));
        this.isPositioned = false;
        this.x = 0;
        this.y = 0;
        this.height = 0;
        this.width = 0;

        if (this.collapsed) {
          await this.prepareCollapsePanel(true);
        }

        this.isExpanding = false;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.platform-card
  background-color: #fff
  border-radius: var(--card-radius)
  position: relative
  transition: all 0.3s ease-in-out
  display: flex
  flex-flow: column
  justify-content: space-between
  box-sizing: border-box
  border: 1px solid rgba(0, 0, 0, 0.1)

  &.is-expanding
    .card-content
      overflow: hidden !important

  &.card-type-blue
    background: rgba(var(--color-card-gray), 1)

  &.not-shown
    z-index: -1
    opacity: 0 !important
    pointer-events: none !important
    *
      pointer-events: none !important

  &.actions-hidden
    >.actions
      transition-delay: 0s !important
      max-height: 0px !important
      padding: 0 var(--card-padding) !important
      overflow: hidden !important
      opacity: 0 !important
      border: none !important
      border-color: transparent

  &.no-overflow
    overflow: visible !important
    .card-content
      overflow: visible !important

  &.no-scroll
    overflow: hidden !important
    .card-content
      overflow: hidden !important

  &.no-padding, &.collapsed
    > .card-content
      padding: 0 !important

  &.no-border-radius
    border-radius: 0 !important

  &.no-lines
    .header, .actions, .footer
      border-color: transparent !important

  &.expanded
    max-width: 100vw !important
    max-height: 100vh !important
    .expandable
      height: 100% !important
      transition: height 0.1s ease-in-out
      transition-delay: .0s !important

  .expandable
    height: 330px
    max-height: 100%
    transition: height 0.1s ease-in-out
    transition-delay: .1s

  &.tablet
    .card-content
      max-height: none !important

  >div
    padding: var(--card-padding)
    transition: all .3s ease-in-out
    transition-delay: .2s
    &.card-content
      overflow-y: auto !important
      flex-grow: 1
      max-height: var(--content-height)
      transition: all .2s ease-in-out
    &.actions
      display: flex
      align-items: center
      justify-content: flex-start
      flex-wrap: wrap
      gap: var(--dashboard-gap)
      position: relative
      max-height: 100px
      transition-delay: .3s !important
      border-top: 1px solid var(--border-color)
      justify-content: flex-end

    &.header
      display: flex
      flex-flow: row
      gap: var(--dashboard-gap)
      align-items: center
      border-bottom: 1px solid var(--border-color)
      .expand-button
        opacity: .2
        transition: all .3s ease-in-out
        &.hovered
          opacity: 1
      h2
        padding: 0 !important
        margin: 0 !important
      >*
        &:first-child
          margin-right: auto

    &.footer
      display: flex
      flex-flow: row
      gap: var(--dashboard-gap)
      align-items: center
      justify-content: space-between
      border-bottom: 1px solid var(--border-color)
</style>
