<template>
  <div class="error-text" :class="{ active: isError, success: success }">
    <transition mode="out-in" name="error-text">
      <div v-if="isError" class="content">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: ""
    },
    success: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isError() {
      return !!this.error;
    }
  }
};
</script>

<style lang="sass" scoped>
.error-text
  color: rgba(var(--color-warning), 1)
  font-size: var(--font-size-extra-small)
  font-weight: var(--font-weight-regular)
  margin: 0 0
  max-height: 0
  transition: all 0.1s ease-in-out
  transition-delay: .1s
  overflow: hidden
  padding-left: 8px
  &.active
    max-height: 100px
    // margin: 6px 0
    animation: shake 0.52s cubic-bezier(.36,.07,.19,.97) both .32s
    transition-delay: 0s
  .content
    overflow: hidden
    display: block
    padding-right: 2px

  &.success
    color: rgba(255,255,255, 1)
    &.active
      animation: none

.error-text-enter-active
  transition: all .2s ease-in-out
  transition-delay: .1s

.error-text-leave-active
  transition-delay: .0s
  transition: all .2s ease-in-out

.error-text-enter
  transform: translateY(-5px)
  opacity: 0
.error-text-leave-to
  transform: translateY(5px)
  opacity: 0
</style>
