const cdbMatchingTableJson = require("~/assets/json/ranos-security-model-matching-table.json");
const clone = require("rfdc")({
  proto: true
});
import Vue from "vue";

export const state = () => ({
  savedMarketCap: "",
  savingMarketCap: false
});

// import { check_if_financial_unit_in_string } from "~/assets/js/SpreadsheetHelper";
export const getters = {
  mstAccessCheckModelUploaded: (state, getters, rootState) => {
    // check if model upload for mst access change
    // if not linked to securities then return false as no modle uploaded
    if (
      !rootState.change.linkedSecurities ||
      rootState.change.linkedSecurities.length == 0
    )
      return false;
    // go through each securities and see if the original storage exist
    const findNoModelSecurities = rootState.change.linkedSecurities.filter(
      (sec) =>
        !sec.model ||
        !sec.model.original_model_file_storage ||
        sec.model.original_model_file_storage === "" ||
        sec.model.original_model_file_storage === "None"
    );
    return findNoModelSecurities.length > 0 ? false : true;
  },
  mstCdbMatchingTable: (state, getters, rootState, rootGetters) => {
    const entity_id = rootGetters["user/currentEntity"]?.entity_id;
    if (!entity_id) return {};
    return cdbMatchingTableJson[entity_id]?.matchingTable?.namedRanges || {};
  },
  mstAccessFinancialTable: (state, getters, rootState, rootGetters) => {
    return (securityObj) => {
      const returnArray = [
        {
          name: "Revenue",
          "t-2": "-",
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
          internal_name: "operating_revenues_generated"
        },
        {
          name: "EBIT",
          "t-2": "-",
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
          internal_name: "operating_ebit_generated"
        },
        {
          name: "Pre-tax Profit",
          "t-2": "-",
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
          internal_name: "net_profit_generated_before_tax"
        },
        {
          name: "Net Profit",
          "t-2": "-",
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
          internal_name: "reported_net_profit_generated_after_tax"
        },
        {
          name: "EPS",
          "t-2": "-",
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
          internal_name: "eps_normalised"
        },
        {
          name: "DPS",
          "t-2": "-",
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
          internal_name: "declared_post-tax_dividend_per_share"
        }
      ];
      const cdbMetricsNameDictionary =
        rootGetters["cdbSheet/cdbMetricsNameDictionary"];
      if (!cdbMetricsNameDictionary) return returnArray;
      if (!securityObj || Object.keys(cdbMetricsNameDictionary) === 0)
        return returnArray;
      for (let i = 0; i < returnArray.length; i++) {
        const foundMetricObj = cdbMetricsNameDictionary[
          returnArray[i].internal_name
        ]
          ? cdbMetricsNameDictionary[returnArray[i].internal_name]
          : null;
        if (!foundMetricObj) continue;
        if (foundMetricObj && foundMetricObj.metrics_id) {
          const newCdb =
            securityObj.change_security_new_cdb &&
            securityObj.change_security_new_cdb.model_sheet_metadata &&
            securityObj.change_security_new_cdb.model_sheet_metadata.length > 0
              ? securityObj.change_security_new_cdb.model_sheet_metadata.find(
                  (tmpCurrentCdb) =>
                    tmpCurrentCdb.metrics_id === foundMetricObj.metrics_id
                )
              : {};
          returnArray[i]["t-2"] = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](newCdb, ["op_sheet_row_values", "t-2"], "-");
          returnArray[i]["t-1"] = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](newCdb, ["op_sheet_row_values", "t-1"], "-");
          returnArray[i]["t+1"] = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](newCdb, ["op_sheet_row_values", "t+1"], "-");
          returnArray[i]["t+2"] = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](newCdb, ["op_sheet_row_values", "t+2"], "-");
          returnArray[i]["t+3"] = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](newCdb, ["op_sheet_row_values", "t+3"], "-");
        }
      }
      return returnArray;
    };
  },
  mstAccessFinancialTableFields: () => {
    return (securityObj) => {
      const returnArray = [
        {
          key: "name",
          label: " "
        },
        {
          key: "t-2",
          label: "t-2"
        },
        {
          key: "t-1",
          label: "t-1"
        },
        {
          key: "t+1",
          label: "t+1"
        },
        {
          key: "t+2",
          label: "t+2"
        },
        {
          key: "t+3",
          label: "t+3"
        }
      ];
      return returnArray;
    };
  },
  CHANGE_REPORT_DISCLAIMER_FOR_EMAIL: () =>
    new Set([
      {
        section: "additional disclosure",
        classToSearch: ".additional-disclosures-content .additional-terms"
      }
    ])
};

export const mutations = {
  updateSavedMarketCap(state, newMarketCap) {
    Vue.set(state, "savedMarketCap", newMarketCap);
  },
  updateSavingMarketCap(state, trueOrFalse) {
    Vue.set(state, "savingMarketCap", trueOrFalse);
  }
};

export const actions = {
  async remoteUpdateMarketCap(
    { rootState, rootGetters, dispatch, commit, state },
    inputNode
  ) {
    if (state.savingMarketCap) {
      console.log("saving already running");
      return;
    }
    commit("updateSavingMarketCap", true);
    if (!inputNode) {
      commit("updateSavingMarketCap", false);
      throw new Error("no input node for market cap");
    }
    const security_count = rootGetters["change/orderedLinkedSecurities"].length;
    if (security_count !== 1) {
      // will need to work out multi security after
      // console.log("no security selected");
      commit("updateSavingMarketCap", false);
      return;
    }
    const CLASS_NAME_TO_CHECK = "valuation-metrics-table-col-6";
    if (!inputNode.classList.contains(CLASS_NAME_TO_CHECK)) {
      commit("updateSavingMarketCap", false);
      return;
    }
    const market_cap = inputNode.innerText;
    if (!market_cap) {
      commit("updateSavingMarketCap", false);
      return;
    }
    // prevent cdb doing calculation
    // remove unwanted characters
    let escaped_market_cap = market_cap
      .replace("\\", "\\\\")
      .replace("'", "\\'")
      .trim();
    if (!escaped_market_cap) escaped_market_cap = "-";
    else escaped_market_cap += " "; // make it a string so no calculation will be done
    if (state.savedMarketCap == escaped_market_cap) {
      commit("updateSavingMarketCap", false);
      return; // already saved
    }
    // should be updating market cap after these check, so can throw errors if no joy
    if (
      !rootState.spreadsheet.cdbIdMap ||
      rootState.spreadsheet.cdbIdMap.length === 0
    ) {
      const getCDBMetrics = await dispatch(
        "spreadsheet/remoteGetCDBMetrics",
        null,
        {
          root: true
        }
      );
      if (!getCDBMetrics) {
        commit("updateSavingMarketCap", false);
        throw Promise.reject(new Error("could not get cdb metrix"));
      }
    }
    if (
      !rootGetters["cdbSheet/cdbMetricsNameDictionary"]["market_capitalisation"]
    ) {
      commit("updateSavingMarketCap", false);
      throw new Error("cannot get market cap id for mst access");
    }
    const market_cap_metric_id =
      rootGetters["cdbSheet/cdbMetricsNameDictionary"]["market_capitalisation"]
        .metrics_id || null;
    if (!market_cap_metric_id) {
      commit("updateSavingMarketCap", false);
      throw new Error(
        "cannot get market cap id from dictionary for mst access"
      );
    }
    const market_cap_map_obj = rootState.spreadsheet.cdbIdMap.find(
      (tmpObj) => tmpObj.metric_id == market_cap_metric_id
    );
    if (!market_cap_map_obj) {
      commit("updateSavingMarketCap", false);
      throw new Error("no mapping to market cap");
    }
    const update_cell_index = market_cap_map_obj.params?.indexOf("t");
    if (update_cell_index < 0) {
      commit("updateSavingMarketCap", false);
      throw new Error("no mapping to value cell for market cap");
    }
    const market_cap_cell = market_cap_map_obj.params_cells[update_cell_index];
    if (!market_cap_cell) {
      commit("updateSavingMarketCap", false);
      throw new Error("cannot not get cell of market cap");
    }
    const cell_decode = rootGetters["spreadsheet/decodeCell"](market_cap_cell);
    if (!cell_decode) {
      commit("updateSavingMarketCap", false);
      throw new Error("cannot decode market cap cell");
    }
    // will need to use index if multiple
    const updatedLinkSec = clone(rootState.change.linkedSecurities[0]);
    const tmpModelId = updatedLinkSec.model.model_id;
    if (!tmpModelId) {
      commit("updateSavingMarketCap", false);
      throw new Error("cannot find model id for mst access market cap");
    }
    await dispatch("spreadsheet/remoteEmptyWorksheetData", null, {
      root: true
    });
    // commit("spreadsheet/removeSheetNameIds", [], { root: true }); // so do not have multiple ids causing delete error
    const setUpTmpWS = await dispatch("mstGetModelDataCdbOnly", tmpModelId);
    if (!setUpTmpWS) {
      commit("updateSavingMarketCap", false);
      throw new Error("cannot setup worksheet for mst access market cap");
    }
    await dispatch("spreadsheet/remoteGetSheetData", null, {
      root: true
    });
    const cdbSheetIndex = updatedLinkSec.model.model_sheets.findIndex(
      (tmpSheet) => tmpSheet.model_sheet_cdb
    );
    if (cdbSheetIndex < 0) {
      commit("updateSavingMarketCap", false);
      throw new Error("cannot find cdb sheet");
    }
    const currentCellValue = rootGetters["spreadsheet/getCellValue"](
      market_cap_cell,
      rootState.spreadsheet.CDB_SHEET_NAME
    );
    if (currentCellValue.length > 0 && currentCellValue == escaped_market_cap) {
      commit("updateSavingMarketCap", false);
      return; // if its the same as the saved value do not update
    }
    updatedLinkSec.change_security_new_cdb.model_sheet_metadata = clone(
      rootState.change.EMPTY_NEW_CDB_METADATA
    );
    const updateCellArray = []; // update on spreadsheet tool, use an array in case multiple in the future
    const pushingObj = {
      id: market_cap_cell,
      col: cell_decode.c,
      row: cell_decode.r,
      value: escaped_market_cap,
      sheetName: rootState.spreadsheet.CDB_SHEET_NAME
    };
    updateCellArray.push(pushingObj);

    commit("spreadsheet/updateCellValueNoCalculation", updateCellArray, {
      root: true
    });
    // if (!updatingR) throw new Error("could not update spreadsheet");
    await dispatch("spreadsheet/remoteUploadCdbSheet", tmpModelId, {
      root: true
    });
    commit("updateSavedMarketCap", escaped_market_cap);
    commit("updateSavingMarketCap", false);
    return;
  },
  async mstGetModelDataCdbOnly({ commit, rootState }, model_id) {
    const result = await this.$axios.$get(
      `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}`
    );
    const workbook = {
      Sheets: {},
      SheetNames: [],
      Workbook: result.model_metadata || { Names: [] }
    };
    if (!workbook.Workbook.Names) workbook.Workbook["Names"] = [];
    const tmpSheetEndCol = {};
    const tmpSheetEndRow = {};
    // even though there is only 1 sheet but kept the array structure as some models might have accidentally multiple sheet from previous method
    const orderedSheets = result.model_sheets
      .filter(
        (sheetObj) =>
          sheetObj.model_sheet_status === "active" && sheetObj.model_sheet_cdb
      )
      .sort(
        (sheetObj1, sheetObj2) =>
          sheetObj1.model_sheet_order - sheetObj2.model_sheet_order
      );
    const tmpUrls = {};
    for (const sheetObj of orderedSheets) {
      commit(
        "spreadsheet/updateSheetNameId",
        {
          sheetName: sheetObj.model_sheet_name,
          sheetId: sheetObj.model_sheet_id
        },
        {
          root: true
        }
      );
      if (sheetObj.model_sheet_storage && sheetObj.model_sheet_storage !== null)
        tmpUrls[sheetObj.model_sheet_name] = sheetObj.model_sheet_storage;
      workbook.Sheets[sheetObj.model_sheet_name] = {};
      workbook.SheetNames.push(sheetObj.model_sheet_name);
      tmpSheetEndCol[sheetObj.model_sheet_name] =
        rootState.ssViewpoints.MAX_COLS;
      tmpSheetEndRow[sheetObj.model_sheet_name] =
        rootState.ssViewpoints.MAX_ROWS;
    }
    if (Object.keys(tmpUrls).length > 0)
      commit("spreadsheet/updateSheetGetUrl", tmpUrls, { root: true });
    commit("spreadsheet/setDefaultWorksheet", workbook, { root: true });
    commit("spreadsheet/updateWorksheetdata", workbook, { root: true });
    // commit('setErrorCells', {});
    commit(
      "spreadsheet/updateColRowCount",
      {
        colCount: tmpSheetEndCol,
        rowCount: tmpSheetEndRow
      },
      {
        root: true
      }
    );
    commit(
      "spreadsheet/setSelectedSheet",
      workbook.SheetNames[1] || workbook.SheetNames[0],
      {
        root: true
      }
    );
    return true;
  },
  async modelDataToCdb({ commit, getters, rootState, rootGetters, dispatch }) {
    // mst has a defined tabled translation to look for in the ranos-security-model-matching-table.json
    if (!getters.mstCdbMatchingTable) throw Error("no cdb matching table");
    if (!rootState.spreadsheet.worksheets) throw Error("no recognised model");
    const mst_to_cdb_match = new Map([
      [
        "Target Price",
        {
          internal_name: "target_price"
        }
      ],
      [
        "Total Revenue",
        {
          internal_name: "operating_revenues_generated"
        }
      ],
      [
        "Total Operating Expenses",
        {
          internal_name: "operating_expenses_incurred"
        }
      ],
      [
        "EBITDA",
        {
          internal_name: "operating_ebitda_generated"
        }
      ],
      [
        "EBIT",
        {
          internal_name: "operating_ebit_generated"
        }
      ],
      [
        "Net Profit Before Tax",
        {
          internal_name: "net_profit_generated_before_tax"
        }
      ],
      [
        "Underlying NPAT",
        {
          internal_name: "adjusted_net_profit_generated_after_tax"
        }
      ],
      [
        "Statutory NPAT",
        {
          internal_name: "reported_net_profit_generated_after_tax"
        }
      ],
      [
        "Underlying EPS",
        {
          internal_name: "eps_normalised"
        }
      ],
      [
        "Cash and Short Term Liquids",
        {
          internal_name: "cash_and_equivalents"
        }
      ],
      [
        "Total Assets",
        {
          internal_name: "total_assets"
        }
      ],
      [
        "Total Equity",
        {
          internal_name: "shareholders_equity"
        }
      ],
      [
        "Total Liabilities",
        {
          internal_name: "total_liabilities_and_equity"
        }
      ],
      [
        "Net Operating Cashflow",
        {
          internal_name: "operating_cashflow_received"
        }
      ],
      [
        "Total Capital Expenditure",
        {
          internal_name: "capital_expenditure_paid"
        }
      ],
      [
        "Dividend Per Share",
        {
          internal_name: "declared_post-tax_dividend_per_share"
        }
      ],
      [
        "Basic Weighted Average Shares",
        {
          internal_name: "shares_on_issue"
        }
      ]
    ]);
    await dispatch("cdbSheet/remoteGetCDBMetrics", null, {
      root: true
    });
    if (!rootState.cdbSheet.cdbMetrics) {
      console.error("could not get cdb details");
      return false;
    }
    const allNamedRangesInWorkBook =
      rootState.spreadsheet.worksheets.Workbook?.Names;
    const allNameRangeMap = new Map();
    for (let i = 0; i < allNamedRangesInWorkBook.length; i++) {
      const currentRangeObj = allNamedRangesInWorkBook[i];
      if (
        currentRangeObj.Name &&
        !currentRangeObj.Hidden &&
        currentRangeObj.Ref
      ) {
        const [sheetName, rangeText] = currentRangeObj.Ref.split("!");
        // sheetname with multiple words will have apostrophe at the beginnging and the end
        let newSheetName = sheetName;
        if (newSheetName.includes(" ")) {
          newSheetName = newSheetName.substr(1, newSheetName.length - 2);
        }
        // const sheetIndex = rootState.spreadsheet.worksheets.SheetNames.indexOf(sheetName);
        // if (sheetIndex < 0) continue;
        if (!rootState.spreadsheet.worksheets.Sheets[newSheetName]) continue;
        const cellIndex = rootGetters["spreadsheet/decodeRange"](rangeText);
        if (!cellIndex.s || !cellIndex.e) {
          console.error(`could not decode cell range: ${currentRangeObj.Ref}`);
          continue;
        }
        allNameRangeMap[currentRangeObj.Name.toLowerCase()] = {
          sheetName: newSheetName,
          cells: cellIndex
        };
      }
    }
    const errorMessage = [];
    const cellUpdateArray = [];
    for (let i = 0; i < getters.mstCdbMatchingTable.length; i++) {
      const currentRange = getters.mstCdbMatchingTable[i];
      const cellRange = allNameRangeMap[currentRange.name.toLowerCase()];
      if (!cellRange) continue;
      if (!currentRange.rows) continue;
      const reverseRowNameTranlate = new Map();
      const possibleRowName = [];
      for (let i = 0; i < currentRange.rows.length; i++) {
        const { name, matches } = currentRange.rows[i];
        if (!matches || !name) {
          console.error(`no matches or name set for row ${i}`);
          continue;
        }
        matches.forEach((tmpMatchName) => {
          const lowerCased = tmpMatchName.toLowerCase();
          reverseRowNameTranlate.set(lowerCased, name);
          possibleRowName.push(lowerCased);
        });
      }
      // loop through the first row from the range to see where the column begins and also workout the years column
      const colFinYearMap = new Map();
      const interestedRowMap = new Map();
      const singleMetricsValue = new Map();
      // const rowUnit = new Map();
      let titleColumnIndex, titleRowIndex, rangeUnit, firstYearColumn;
      const ws = rootState.spreadsheet.worksheets.Sheets[cellRange.sheetName];
      if (!ws["!cols"]) ws["!cols"] = {};
      if (!ws["!rows"]) ws["!rows"] = {};
      // just checking the columns and rows to see where to start
      for (let col = cellRange.cells.s.c; col <= cellRange.cells.e.c; col++) {
        // dont take the values that are hidden
        if (ws["!cols"] && ws["!cols"][col] && ws["!cols"][col].hidden)
          continue;
        if (!titleRowIndex) {
          // assuming the first row/column with value is the right one
          for (
            let row = cellRange.cells.s.r;
            row <= cellRange.cells.e.r;
            row++
          ) {
            if (ws["!rows"] && ws["!rows"][row] && ws["!rows"][row].hidden)
              continue;
            const cellValue = rootGetters["spreadsheet/getCellObj"](
              false,
              cellRange.sheetName,
              row,
              col
            );
            if (!cellValue || !cellValue.v) continue;
            const nameToCheck =
              cellValue.w?.toLowerCase().trim() ||
              cellValue.v.toLowerCase().trim();
            if (!nameToCheck) continue;
            const tmpWordList = nameToCheck.split(" ");
            const tmpLastWord = tmpWordList[tmpWordList.length - 1];
            if (!titleRowIndex) {
              titleRowIndex = row;
              titleColumnIndex = col;
              // check if the unit is in the title row
              // if the last word is in bracket then assume its the unit
              if (tmpLastWord.startsWith("(") && tmpLastWord.endsWith(")")) {
                rangeUnit = tmpLastWord;
              }
            } else {
              // determine which rows should be looked at
              let foundMatch;
              if (reverseRowNameTranlate.has(nameToCheck)) {
                // if the name matches exactly
                const tmpRowName = reverseRowNameTranlate.get(nameToCheck);
                interestedRowMap.set(tmpRowName, row);
                foundMatch = tmpRowName;
                // check if the unit is in the title row
                // if the last word is in bracket then assume its the unit
                // const unitIndex = check_if_financial_unit_in_string(tmpLastWord)
                // if (unitIndex && unitIndex >= 0) {
                //   rowUnit.set(tmpRowName, tmpLastWord);
                // }
              } else {
                // not does not match exactly, see if consist of it
                reverseRowNameTranlate.forEach((value, tmpPossibleRowName) => {
                  if (foundMatch) return;
                  if (interestedRowMap.has(value)) return;
                  if (nameToCheck.includes(tmpPossibleRowName)) {
                    interestedRowMap.set(value, row);
                    foundMatch = value;
                  }
                });
              }
              if (!foundMatch) continue;
              // this is an interested row
              if (currentRange.name.endsWith("_single_metrics")) {
                // for single metrics will need to loop through all columns and find the value
                for (
                  let singleMetricsCol = col + 1;
                  singleMetricsCol <= cellRange.cells.e.c;
                  singleMetricsCol++
                ) {
                  if (
                    ws["!cols"] &&
                    ws["!cols"][singleMetricsCol] &&
                    ws["!cols"][singleMetricsCol].hidden
                  )
                    continue;
                  const singleMetricsCellValue = rootGetters[
                    "spreadsheet/getCellObj"
                  ](false, cellRange.sheetName, row, singleMetricsCol);
                  if (
                    singleMetricsCellValue &&
                    singleMetricsCellValue.v &&
                    !isNaN(parseFloat(singleMetricsCellValue.v))
                  ) {
                    // check if it has number in there - if so assume its the right number (currently only valuation)
                    singleMetricsValue.set(foundMatch, singleMetricsCellValue);
                    break;
                  }
                }
              }
            }
          }
        } else {
          // already know the right row and column, workout the date heading
          const cellValue = rootGetters["spreadsheet/getCellObj"](
            false,
            cellRange.sheetName,
            titleRowIndex,
            col
          );
          if (!cellValue || !cellValue.v) continue;
          const columnName =
            cellValue.w?.toLowerCase() || cellValue.v.toLowerCase();
          // assume the first column with letter F(forecast) or E(expected) to be the current column (t+1)
          if (["e", "f"].includes(columnName[columnName.length - 1])) {
            if (col > 0) {
              colFinYearMap.set("t+1", col);
              firstYearColumn = col - 1;
              const minus2Column = firstYearColumn - 1;
              // some model does not have t-2
              if (minus2Column != titleColumnIndex)
                colFinYearMap.set("t-2", minus2Column);
              colFinYearMap.set("t-1", firstYearColumn);
              colFinYearMap.set("t+2", col + 1);
              colFinYearMap.set("t+3", col + 2);
            } else {
              console.error(
                `column ${col + 1} for ${
                  currentRange.head
                } does not have previous year`
              );
            }
            break;
          }
        }
      }
      // console.log(interestedRowMap);
      // console.log(colFinYearMap);
      // console.log(singleMetricsValue);
      // have got the columns and row of interest now to set the valuve of cdb accordingly
      if (interestedRowMap.size == 0) {
        // if not interested rows, do not need to find values just go to the next name range
        errorMessage.push(
          `${currentRange.head} does not have any row that matched`
        );
        continue;
      }
      if (colFinYearMap.size == 0) {
        errorMessage.push(
          `${currentRange.head} could not find matching year columns`
        );
      }
      if (colFinYearMap.size < 4) {
        errorMessage.push(
          `${currentRange.head} is missing certain year columns`
        );
      }
      interestedRowMap.forEach((rowValue, mappedRowName) => {
        const mappedInternalKey = mst_to_cdb_match.get(mappedRowName);
        if (!mappedInternalKey) return;
        const internal_name = mappedInternalKey.internal_name;
        const cdbObj =
          rootGetters["cdbSheet/cdbMetricsNameDictionary"][internal_name] ||
          null;
        if (!cdbObj) {
          console.error(`could not get cdb information for ${internal_name}`);
          return;
        }
        const cellObj = rootState.spreadsheet.cdbIdMap.find(
          (tmpObj) => tmpObj.metric_id == cdbObj.metrics_id
        );
        if (!cellObj) {
          console.error(
            `could not find cdb metric id to cell mapping for ${internal_name}`
          );
          return;
        }
        // check if the column prior to the first year column has unit in there
        // NOTE: do it here because I need the year column and the row name
        /*
        for (let j = titleColumnIndex + 1; j < firstYearColumn; j++) {
          const unitCell = rootGetters["spreadsheet/getCellObj"](
            "", // use row and column index
            cellRange.sheetName,
            rowValue,
            j
          );
          if (!unitCell || !unitCell.v) continue;
          else {
            // const hasUnit = check_if_financial_unit_in_string(unitCell.w);
            // if (hasUnit && hasUnit >= 0) {
            //   rowUnit.set(mappedRowName, unitCell.v);
            //   break;
            // }
          }
        }
        */
        if (singleMetricsValue.has(mappedRowName)) {
          //use the value for the rows without years
          const updateCellIndex = cellObj.params?.indexOf("t");
          if (updateCellIndex < 0) {
            console.error(
              `could not find the cell object for year t of ${internal_name}`
            );
            return;
          }
          const updateCell = cellObj.params_cells[updateCellIndex];
          if (!updateCell) {
            console.error(
              `could not map cell index to actually number for t of ${internal_name}`
            );
            return;
          }
          const cell_decode = rootGetters["spreadsheet/decodeCell"](updateCell);
          if (!cell_decode) {
            console.error(`could decode cell for ${updateCell}`);
            return;
          }
          const currentCellValue = singleMetricsValue.get(mappedRowName);
          const pushingObj = {
            id: updateCell,
            col: cell_decode.c,
            row: cell_decode.r,
            value: currentCellValue.v,
            format: currentCellValue.z || false,
            displayText: currentCellValue.w || currentCellValue.v,
            // style: currentCellValue.s || false,
            sheetName: rootState.spreadsheet.CDB_SHEET_NAME
          };
          cellUpdateArray.push(pushingObj);
        }
        colFinYearMap.forEach((yearColumnValue, yearValue) => {
          const currentCellValue = rootGetters["spreadsheet/getCellObj"](
            "", // use row and column index
            cellRange.sheetName,
            rowValue,
            yearColumnValue
          );
          if (!currentCellValue || currentCellValue.w == "") {
            errorMessage.push(
              `value is empty for ${cellRange.sheetName}, for row ${mappedRowName} and column ${yearValue}`
            );
            return;
          }

          const updateCellIndex = cellObj.params?.indexOf(yearValue);
          if (updateCellIndex < 0) {
            console.error(
              `could not find the cell object for year ${yearValue} of ${internal_name}`
            );
            return;
          }
          const updateCell = cellObj.params_cells[updateCellIndex];
          if (!updateCell) {
            console.error(
              `could not map cell index to actually number for ${yearValue} of ${internal_name}`
            );
            return;
          }
          const cell_decode = rootGetters["spreadsheet/decodeCell"](updateCell);
          if (!cell_decode) {
            console.error(`could decode cell for ${updateCell}`);
            return;
          }
          const pushingObj = {
            id: updateCell,
            col: cell_decode.c,
            row: cell_decode.r,
            value: currentCellValue.v,
            format: currentCellValue.z || false,
            displayText: currentCellValue.w || currentCellValue.v,
            // style: currentCellValue.s || false,
            sheetName: rootState.spreadsheet.CDB_SHEET_NAME
          };
          cellUpdateArray.push(pushingObj);
          // check if the unit can be updated as well
          // const cellUnit = rowUnit.get(mappedRowName) || rangeUnit;
          // if (!cellUnit) return;
          const unitCellIndex = cellObj.params?.indexOf("unit");
          if (unitCellIndex < 0) {
            console.error(
              `could not find the cell object for unit ${yearValue} of ${internal_name}`
            );
            return;
          }

          const updateUnitCell = cellObj.params_cells[unitCellIndex];
          if (!updateUnitCell) {
            console.error(
              `could not map UNIT cell index to actually number for ${yearValue} of ${internal_name}`
            );
            return;
          }
          const unit_cell_decode =
            rootGetters["spreadsheet/decodeCell"](updateUnitCell);
          if (!unit_cell_decode) {
            console.error(`could decode cell for ${updateCell}`);
            return;
          }
          // const unitPushingObj = {
          //   id: updateUnitCell,
          //   col: unit_cell_decode.c,
          //   row: unit_cell_decode.r,
          //   value: cellUnit,
          //   displayText: cellUnit,
          //   // style: currentCellValue.s || false,
          //   sheetName: rootState.spreadsheet.CDB_SHEET_NAME
          // };
          // cellUpdateArray.push(unitPushingObj);
        });
      });
    }
    if (cellUpdateArray.length > 0)
      commit("spreadsheet/updateCellValueNoCalculation", cellUpdateArray, {
        root: true
      });
    return true;
  }
};
